import {useQuery} from "@tanstack/react-query";
import {useCallback, useMemo} from "react";
import JobRest from "../../rest/JobRest.ts";
import Statement from "../../components/Statements/Statement.tsx";
import {SentimentVeryDissatisfied} from "@mui/icons-material";
import {Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AddCard, CardGridSingleLine, StandardCard, StandardCardProps} from "@laser-project/kyui";
import {IJob, JOB_STATUS_CATEGORY} from "../../types/models/Job.ts";
import {useDropzone} from "react-dropzone";
import ImageRest from "../../rest/ImageRest.ts";
import JobWebsocket from "../../websocket/JobWebsocket.ts";
import {IPopulatedJob} from "../../types/FrontendTypes.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import {getStatusChipProps} from "../../components/StatusChips/StatusChips.tsx";
import CutterWebsocket from "../../websocket/CutterWebsocket.ts";

function JobOverviewPage() {
    const navigate = useNavigate();

    const jobRest = useMemo(() => new JobRest(), []);
    const jobWebsocket = useMemo(() => new JobWebsocket(), []);
    const cutterWebsocket = useMemo(() => new CutterWebsocket(), []);
    const imageRest = useMemo(() => new ImageRest(), []);

    const useJobsByStatus = (statuses: string[]) => {
        return useQuery({
            queryKey: ['jobs', statuses],
            queryFn: () => jobRest.getByStatus(statuses).then(res => res.data as { data: IJob[] })
        });
    };

    const jobsCreated = useJobsByStatus(JOB_STATUS_CATEGORY.CREATED);
    const jobsRunning = useJobsByStatus(JOB_STATUS_CATEGORY.RUNNING);
    const jobsFinished = useJobsByStatus(JOB_STATUS_CATEGORY.FINISHED);

    const refetchJobs = useCallback(() => {
        jobsCreated.refetch();
        jobsRunning.refetch();
        jobsFinished.refetch();
    }, [jobsCreated, jobsRunning, jobsFinished]);

    // It is intended, that messages received by the cutterWebsocket trigger a refetch of the jobs.
    // This is because the cutter object is populated in the job object, so by refetching the jobs,
    // the cutter object is also updated.
    useWebsocketHandler({webSocketUrl: jobWebsocket.getAllUpdates(), refetch: refetchJobs})
    useWebsocketHandler({webSocketUrl: cutterWebsocket.getAllUpdates(), refetch: refetchJobs})

    // Handling file drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            console.log("Uploaded file", file)
            const reader = new FileReader()

            reader.onabort = () => console.error('file reading was aborted')
            reader.onerror = () => console.error('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr: AllowSharedBufferSource = reader.result as AllowSharedBufferSource
                if (!binaryStr) {
                    console.log("failed to read file")
                    return
                }

                if (file.type === "image/svg+xml") {
                    const data = new FormData();
                    data.append("file", file)
                    jobRest.createJobByFile(data)
                    return
                }

                const cutplanContent: string = new TextDecoder("utf-8").decode(binaryStr);
                console.log(cutplanContent)
                jobRest.createJob({
                    title: file.name, cutplan: cutplanContent, sheetPrototype: {
                        "outline": {
                            "points": [{"x": 0, "y": 0}, {"x": 594, "y": 0}, {"x": 594, "y": 420}, {"x": 0, "y": 420}]
                        }
                    }
                })
                    .then(() => refetchJobs())

            }
            reader.readAsArrayBuffer(file)
        })
    }, [jobRest, refetchJobs])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop, accept: {
            'application/xml': ['.cut'], 'image/svg+xml': ['.svg']
        }
    });

    function generateCards(jobs, withAddCard: boolean = false) {
        const {data, refetch} = jobs;
        const addCard = (<div {...getRootProps()}>
            <input {...getInputProps()}/>
            <AddCard/>
        </div>)

        if (!data && withAddCard) {
            return [addCard]
        }
        const modelCards = data?.data?.map((job: IPopulatedJob) => (<StandardCard
            key={job._id}
            image={imageRest.getUrlById(job.imageId)}
            imageAlt={"Rendered Model of " + job.title}
            title={job.title ?? "No title given"}
            chips={getStatusChipProps(job) as unknown as StandardCardProps["chips"]}
            onClick={() => navigate("/job/" + job._id)}
            type={"model"}
            menuActions={[
                {
                    title: "Duplicate",
                    onClick: () => jobRest.duplicate(job._id).then(() => refetch())
                },
                {
                    title: "Reset",
                    onClick: () => jobRest.reset(job._id).then(() => refetch())
                },
                {
                    title: "Delete",
                    onClick: () => jobRest.delete(job._id).then(() => refetch())
                },
            ]}
        />))

        if (!withAddCard) {
            return modelCards
        }
        return [addCard, ...modelCards]
    }

    function generateCardGridWithTitle(jobs, title: string, withAddCard: boolean = false) {
        let content = <CardGridSingleLine
            isLoading={jobs.isLoading}
            cards={generateCards(jobs, withAddCard)}
        />;

        if (jobs.error) {
            const errorMessage = jobs.error?.message;
            content = (<Statement message={errorMessage} icon={<SentimentVeryDissatisfied/>}/>);
        }

        return <>
            <Typography variant={"h4"} sx={{mt: 3, mb: 1}}>{title}</Typography>
            {content}
        </>
    }

    return (<Container sx={{p: 4}}>
        <Typography variant={"h3"}>Jobs</Typography>
        {generateCardGridWithTitle(jobsCreated, "Created", true)}
        {generateCardGridWithTitle(jobsRunning, "Running", false)}
        {generateCardGridWithTitle(jobsFinished, "Finished", false)}
    </Container>)

}

export default JobOverviewPage