import {styled} from "@mui/system";
import {PlayCircle} from "@mui/icons-material";

// ToDo: Add pulsing icon option in Kyui

export const PulsingIcon = styled(PlayCircle)(() => ({
    animation: "pulse 1.5s infinite ease-in-out",
    "@keyframes pulse": {
        "0%, 100%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0.5,
        },
    },
}));