import {ITimestamped} from "./Database.ts";

export interface ICutterSpec {
    name: string;
}

export enum CUTTER_STATUS {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export enum CUTTER_STATUS_LABEL {
    ONLINE = "Online",
    OFFLINE = "Offline",
}

export enum CUTTER_STATE {
    BUSY = "BUSY",
    IDLE = "IDLE",
}

export enum CUTTER_STATE_LABEL {
    BUSY = "Busy",
    IDLE = "Idle",
}

export interface ICutterState {
    isCutterBusy: boolean;
    jobId?: string | null;
}

export interface ICutter extends ICutterSpec, ITimestamped {
    _id: string;
    status: CUTTER_STATUS;
    lastSeen?: Date;
    state: ICutterState;
}
