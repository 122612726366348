import React, { ReactElement, ReactNode, useEffect, useState} from "react";
import {Box, Fade, Typography} from "@mui/material";
import PropTypes from "prop-types";
import LoadingSpinnerStyles from "../LoadingSpinner/LoadingSpinnerStyles.ts";

export interface StatementProps {
    message?: string,
    reason?: string,
    icon: ReactElement,
    title?: string,
    action?: ReactNode
}

function Statement(props: StatementProps) {

    const [loadingTextShowing, setLoadingTextShowing] = useState(false);

    const {message, reason, icon, title, action} = props;

    useEffect(() => {
        setTimeout(() => setLoadingTextShowing(true), 500)
    }, [])

    return (
        <Box sx={LoadingSpinnerStyles.wrapper}>
            <Box sx={LoadingSpinnerStyles.child}>
                <Fade in={loadingTextShowing}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {React.cloneElement(icon, {style: {fontSize: "4rem"}})}
                        {title ? <Typography variant={"h4"}
                                             sx={LoadingSpinnerStyles.message}>{title}</Typography> : null}
                        <Typography variant={"body1"} sx={LoadingSpinnerStyles.message}>{message}</Typography>
                        {reason && <Typography variant={"body2"} sx={LoadingSpinnerStyles.reason}>{reason}</Typography>}
                        {action}
                    </div>
                </Fade>
            </Box>
        </Box>
    )
}

Statement.defaultProps = {
    message: "Loading"
}

Statement.propTypes = {
    icon: PropTypes.element.isRequired,
    message: PropTypes.string
}

export default Statement;
