import {Route, Routes} from "react-router-dom";
import JobOverviewPage from "../../pages/JobOverviewPage/JobOverviewPage.tsx";
import {StepByStepPage} from "../../pages/StepByStepPage/StepByStepPage.tsx";
import {CutJobPage} from "../../pages/CutJobPage/CutJobPage.tsx";
import CutterOverviewPage from "../../pages/CutterOverviewPage/CutterOverviewPage.tsx";

function Router(){

    return(
            <Routes>
                <Route path={"/cutters"} element={<CutterOverviewPage/>}/>
                <Route path={"/job/:jobId/stepByStep"} element={<StepByStepPage />}/>
                <Route path={"/job/:jobId"} element={<CutJobPage/>}/>
                <Route path={"/"} element={<JobOverviewPage/>}/>
            </Routes>
    )
}
export default Router;