import {
    AppBar,
    Avatar,
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, {ReactNode} from "react";
import MenuIcon from "@mui/icons-material/Menu"
import KalibratorLogo from "../../../assets/Calibrator_Blue.svg"
import {useAuth} from "oidc-react";
import {useNavigate} from "react-router-dom";


export interface DesktopNavigationProps {
    children: ReactNode
}

function DesktopNavigation(props: DesktopNavigationProps) {

    const navigate = useNavigate();

    const pages = [
        {
            title: "my projects",
            active: location.pathname === "/",
            onClick: () => navigate("/")
        },
        {
            title: "cutters",
            active: location.pathname === "/cutters",
            onClick: () => navigate("/cutters")
        }
    ] as {
        title: string,
        active: boolean,
        onClick: () => void,
        type?: "spacer",
        element?: any
    }[]

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const auth = useAuth();

    const settings = [
        {title: "Logout", onClick: () => auth.signOutRedirect().then(() => window.location.reload())}
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <>
            <AppBar position="static">
                <Container maxWidth={false}>
                    <Toolbar disableGutters sx={{display: "flex", alignItems: "center"}}>
                        <Box
                            component={"img"}
                            src={KalibratorLogo}
                            height={"1.5rem"}
                            onClick={() => navigate("/")} alt={"Kalibrator Logo"}
                            sx={{
                                mr: 2,
                                cursor: "pointer"
                            }}
                        />

                        <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                            <Box sx={{
                                display: {xs: "flex", md: "none"}
                            }}/>

                        </Box>

                        <Box sx={{flexGrow: 1, gap: 1, display: {xs: "none", md: "flex"}, alignItems: "center"}}>

                            {pages.map((page) => {
                                if (page.type === "spacer") {
                                    return <Box/>;
                                }
                                return (
                                    <Typography
                                        noWrap
                                        key={page.title}
                                        onClick={() => {
                                            handleCloseNavMenu()
                                            page.onClick()
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: (page.active ? "underline" : "none"),
                                            "&:hover": {textDecoration: "underline"}
                                        }}
                                    >
                                        {page?.title}
                                    </Typography>
                                );
                            })}

                        </Box>

                        <Box sx={{flexGrow: 0, display: "flex", alignItems: "center"}}>

                            <Box>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    sx={{
                                        display: {xs: "flex", md: "none"},
                                        mr: 1

                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Box>
                            <Box>
                                <Tooltip title="Profile">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt={"Profile Picture of "}
                                                src={""}
                                        >
                                            {auth?.userData?.profile?.preferred_username[0]?.toUpperCase()}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor={"right"}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
            >
                <Box
                    component={"img"}
                    src={KalibratorLogo}
                    height={"1.5rem"}
                    onClick={() => navigate("/")}
                    alt={"Kalibrator Logo"}
                    sx={{
                        mr: 2
                    }}/>
                <Divider/>
                <Box sx={{width: "60vw"}}>
                    <List>
                        {pages.map((item, index) => {
                            if (item.type === "spacer") {
                                return <Box sx={{height: ".75rem"}} key={index}/>;
                            }
                            if (item.element) {
                                return <Box key={index} sx={{
                                    cursor: "pointer",
                                    textDecoration: item.active ? "underline" : "none !important"
                                }}>{item.element}</Box>;
                            }
                            return (
                                <ListItem key={item.title} disablePadding sx={{
                                    cursor: "pointer",
                                    textDecoration: (item.active ? "underline" : "none"),
                                    "&:hover": {textDecoration: "underline"}
                                }}
                                          onClick={() => item.onClick()}>
                                    <ListItemButton>
                                        <ListItemText primary={item.title}/>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
            <Menu
                sx={{mt: "45px"}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting.title} onClick={setting.onClick}>
                        <Typography textAlign="center">{setting.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            {props.children}
        </>
    )
}

export default DesktopNavigation;