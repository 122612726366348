import React, {useEffect, useMemo, useState} from "react";
import JobRest from "../../rest/JobRest.ts";
import ImageRest from "../../rest/ImageRest.ts";
import {useNavigate} from "react-router-dom";
import {IFeature} from "../../types/models/Feature.ts";
import {useCutJobApi} from "./useCutJobApi";
import {uniqBy} from "lodash";
import {IGauge, IGaugeSpec} from "../../types/models/Gauge.ts";
import {enqueueSnackbar} from "notistack";
import {GaugesSlider} from "./GaugesSlider";
import {FeatureSlider} from "./FeatureSlider";
import {JOB_STATUS} from "../../types/models/Job.ts";
import {Box, Container, Skeleton, Stack, Typography} from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner.tsx";
import {CardSlider} from "./CardSlider";
import {Expandable, ImageHeader} from "@laser-project/kyui";
import Statement from "../../components/Statements/Statement.tsx";
import {
    Clear,
    DoneOutlined,
    Error,
    HourglassTop,
    InfoOutlined,
    Report,
    SignalWifiConnectedNoInternet4
} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {renderStatusChips} from "../../components/StatusChips/StatusChips.tsx";
import CutterSelectionDialog from "./CutterSelectionButton/CutterSelectionButton.tsx";
import {CUTTER_STATUS} from "../../types/models/Cutter.ts";

export function CutJob({jobId}: Readonly<{ jobId?: string }>) {
    const navigate = useNavigate();

    // Job Interfaces
    const jobRest = useMemo(() => new JobRest(), []);
    const imageRest = useMemo(() => new ImageRest(), []);

    const [filteredFeatures, setFilteredFeatures] = React.useState<IFeature[] | null>(null);
    const [images, setImages] = useState([])
    const {
        handleUserInputSubmit,
        handleStart,
        isSubmitting,
        activeActionId,
        job,
        assignCutter,
        cutters,
        lastErrorMessage,
        isStartingJob
    } = useCutJobApi({jobId})

    // Fetching features for job
    useEffect(() => {
        if (!jobId) {
            console.warn("Did not have jobId. Aborting")
            return;
        }
        jobRest
            .getFeaturesByJobId(jobId)
            .then(response => {
                const allFeatures = response.data.data;
                let features: IFeature[] = uniqBy(allFeatures, (feature: IFeature) => feature.groupingKey);
                const blacklistedDependencyKeys: string[] = []
                features = features.map(feature => {
                    const newFeature = {...feature};
                    const cleanedDeps: (IGauge | IGaugeSpec)[] = [];
                    newFeature.dependencies?.forEach(dep => {
                        if (!blacklistedDependencyKeys.includes(dep.groupingKey)) {
                            blacklistedDependencyKeys.push(dep.groupingKey);
                            cleanedDeps.push(dep);
                        }
                    })
                    newFeature.dependencies = cleanedDeps;
                    return newFeature;
                })

                features.forEach(
                    feature => feature.entityType = "feature"
                )

                setFilteredFeatures(features)
            })
    }, [jobId, jobRest]);

    useEffect(() => {
        setImages([imageRest.getUrlById(job?.imageId ?? "")])
    }, [imageRest, job, job?.imageId]);

    useEffect(() => {
        if (!lastErrorMessage || lastErrorMessage === "") {
            return;
        }
        enqueueSnackbar(lastErrorMessage, {
            variant: 'error',
        })
    }, [lastErrorMessage]);

    const isMakeDisabled = job?.status !== JOB_STATUS.ANALYSIS_COMPLETE ||
        job?.cutterId === undefined ||
        job.cutter?.state.isCutterBusy ||
        job.cutter?.status === CUTTER_STATUS.OFFLINE;


    function renderGauges() {
        return <GaugesSlider filteredFeatures={filteredFeatures}/>
    }

    function renderFeatures() {
        return <FeatureSlider filteredFeatures={filteredFeatures}/>
    }

    function renderJobPerStage() {
        if (!job) {
            return <Skeleton height={500}/>
        }

        const resetButton = <LoadingButton sx={{mt: 2}}
                                           color={"secondary"}
                                           onClick={() => jobRest.reset(job?._id).then(null)}
                                           variant={"outlined"}>Reset Job</LoadingButton>;

        switch (job?.status) {
            case JOB_STATUS.UNASSIGNED: {
                return (
                    <Statement icon={<InfoOutlined/>} message={"To analyze this job, please assign a cutter."}/>
                )
            }
            case JOB_STATUS.ANALYSIS_PENDING: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Select different cutter or wait until cutter is online, to analyze this job."}
                    />);
                }
                let reason = null;
                if (job.cutter?.state.isCutterBusy && job.cutter?.state.jobId !== job._id) {
                    reason = "The selected cutter is currently busy.";
                }

                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Waiting for cutter to analyze this job"} reason={reason}/>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                )
            }
            case JOB_STATUS.ANALYSIS_STARTED: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while executing this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Job is being analyzed"}/>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                )
            }
            case JOB_STATUS.ANALYSIS_FAILED:
                return (
                    <Box pb={10}>
                        <Statement
                            icon={<Report/>}
                            message={"Your Model could not be analyzed!"}
                            reason={job.statusReason}
                            action={resetButton}
                        />
                    </Box>
                )
            case JOB_STATUS.ANALYSIS_COMPLETE: {
                let statement = null;
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    statement = <Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Select different cutter or wait until cutter is online, to make this job."}
                    />;
                } else if (job.cutter?.state.isCutterBusy && job.cutter?.state.jobId !== job._id) {
                    statement = <Statement
                        icon={<HourglassTop/>}
                        message={"Cutter is busy"}
                        reason={"Select different cutter or wait until cutter is idle, to make this job."}
                    />;
                }
                return (<>
                    {statement}
                    <Stack spacing={3}>
                        <Expandable title={"Gauges"}>
                            {renderGauges()}
                        </Expandable>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                </>)
            }
            case JOB_STATUS.EXECUTION_PENDING: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while trying to execute this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Waiting for cutter to execute this job"}/>
                        {renderFeatures()}
                    </Stack>
                )
            }
            case JOB_STATUS.EXECUTION_WAITING_FOR_INPUT:
            case JOB_STATUS.EXECUTION_STARTED:
            case JOB_STATUS.EXECUTION_CALIBRATING:
            case JOB_STATUS.EXECUTION_FABRICATING:
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while execute this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <CardSlider
                            handleUserInputSubmit={handleUserInputSubmit}
                            elements={job.actionOrder ?? []}
                            activeElementId={activeActionId}
                            currentSubmitting={isSubmitting}
                        />
                        <Expandable title={"Gauges"}>
                            {renderGauges()}
                        </Expandable>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                )
            case JOB_STATUS.EXECUTION_SUCCESS:
                return (
                    <Box pb={10}>
                        <Statement icon={<DoneOutlined/>} message={"Your Model is done!"}/>
                    </Box>
                )
            case JOB_STATUS.EXECUTION_FAILED:
                return (
                    <Box pb={10}>
                        <Statement
                            icon={<Report/>}
                            message={"Your Model could not be produced!"}
                            reason={job.statusReason}
                            action={resetButton}
                        />
                    </Box>
                )
            default:
                return (
                    <Box pb={10}>
                        <Statement icon={<Error/>} message={"Unknown Job Status"}/>
                    </Box>
                )
        }
    }

    function renderImageHeaderActions() {
        return [
            <LoadingButton
                key={"makeButton"}
                color={"secondary"}
                loading={isStartingJob}
                disabled={isMakeDisabled}
                onClick={handleStart}
                variant={"contained"}
            >
                Make
            </LoadingButton>,
            <CutterSelectionDialog
                key="cutter-selection-dialog"
                job={job}
                assignCutter={assignCutter}
                cutters={cutters}
            />,
        ]
    }

    if (!jobId) {
        return <Statement icon={<Clear/>} message={"No Job Specified"}/>
    }

    return (
        <Box sx={{width: '100%', overFlowY: ""}}>
            <Container sx={{p: 4}}>
                <Stack spacing={3}>
                    <ImageHeader
                        images={images}
                        isLoading={!job}
                        menuActions={[
                            {
                                title: "Step by Step",
                                onClick: () => navigate("/job/" + jobId + "/stepByStep")
                            },
                            {
                                title: "Duplicate",
                                onClick: () => jobRest.duplicate(job._id).then((newJob) => navigate("/job/" + newJob.data.data._id))
                            },
                            {
                                title: "Reset",
                                onClick: () => jobRest.reset(job._id).then(null)
                            },
                            {
                                title: "Delete",
                                onClick: () => jobRest.delete(job._id).then(() => navigate("/"))
                            },
                        ]}
                        actions={renderImageHeaderActions()}
                    />
                    <Stack spacing={1}>
                        <Typography variant={"h1"}>{job?.title ?? <Skeleton/>}</Typography>
                        {renderStatusChips(job)}
                    </Stack>
                    {renderJobPerStage()}
                </Stack>
            </Container>
        </Box>
    )

}