import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {IPopulatedCutter, IPopulatedJob} from "../../../types/FrontendTypes.ts";
import {
    CUTTER_STATE,
    CUTTER_STATE_LABEL,
    CUTTER_STATUS,
    CUTTER_STATUS_LABEL,
    ICutter
} from "../../../types/models/Cutter.ts";
import {Chip} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

export interface CutterSelectionDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    job: IPopulatedJob;
    cutters: IPopulatedCutter[];
}

function getCutterSecondaryText(cutter: ICutter) {
    const status = CUTTER_STATUS_LABEL[cutter.status]
    if (cutter.status === CUTTER_STATUS.ONLINE) {
        const state = CUTTER_STATE_LABEL[cutter.state.isCutterBusy ? CUTTER_STATE.BUSY : CUTTER_STATE.IDLE]
        return `${status} · ${state}`
    }
    return status;
}

export function CutterSelectionDialog(props: CutterSelectionDialogProps) {
    const {onClose, selectedValue, open, job, cutters} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };
    
    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Assign to cutter</DialogTitle>
            <List sx={{pt: 0}}>
                {cutters.map((cutter) => (
                    <ListItem disablePadding key={cutter._id}>
                        <ListItemButton
                            selected={job.cutterId == cutter._id}
                            // Highlight selected cutter, because MUI does not change the style when selected=true
                            onClick={() => handleListItemClick(cutter._id)}>
                            <ListItemText
                                primary={cutter.name}
                                secondary={getCutterSecondaryText(cutter)}/>
                            {job.cutterId == cutter._id &&
                                <Chip icon={<CheckIcon/>} sx={{ml: "1em"}} label="Assigned"/>}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
