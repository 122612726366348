import React, {useEffect, useState} from "react";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {ReadyState} from "react-use-websocket";
import {Box, CircularProgress, Stack} from "@mui/material";
import {useLocation} from "react-router-dom";

export interface useWebsocketNotifierProps {
    readyState: ReadyState,
    showConnected?: boolean
}

export function useWebsocketNotifier(props: useWebsocketNotifierProps) {
    const [snackBarState, setSnackBarState] = useState<{ id: string, lastState: ReadyState, pathname: string }>(null)
    const location = useLocation();

    useEffect(() => {
        return () => {
            closeSnackbar(snackBarState?.id)
        }
    }, []);

    useEffect(() => {
        let snackbarId;

        if ([ReadyState.CONNECTING, ReadyState.CLOSING].includes(props?.readyState)) {
            return;
        }

        if (snackBarState && snackBarState?.lastState === props.readyState) {
            return;
        }

        if (snackBarState && snackBarState.lastState !== props.readyState) {
            closeSnackbar(snackBarState.id)
        }

        switch (props.readyState) {
            /*case ReadyState.CONNECTING:
                snackbarId = enqueueSnackbar(<Stack spacing={1} direction={"row"}><CircularProgress size={20} color={"inherit"}/><Box>Connecting</Box></Stack>, {
                    variant: 'default',
                })
                break*/
            case ReadyState.OPEN:
                if (!props.showConnected) {
                    return;
                }
                snackbarId = enqueueSnackbar('Connected', {
                    variant: 'success',
                })
                break
            case ReadyState.UNINSTANTIATED:
                snackbarId = enqueueSnackbar("Disconnected", {
                    variant: 'error',
                    persist: true,
                })
                break;
            case ReadyState.CLOSED:
                snackbarId = enqueueSnackbar(
                    <Stack
                        spacing={2}
                        direction={"row"}
                    >
                        <CircularProgress
                            size={20}
                            color={"inherit"}
                        />
                        <Box>
                            Disconnected
                        </Box>
                    </Stack>
                    , {
                        variant: 'error',
                        persist: true,
                        hideIconVariant: true
                    })
                break

        }
        setSnackBarState({id: snackbarId, lastState: props.readyState, pathname: location.pathname})

    }, [props.readyState]);
}