import AbstractWebsocket from "./AbstractWebsocket.ts";

class CutterWebsocket extends AbstractWebsocket {

    constructor() {
        super("cutters")
    }

    public getAllUpdates() {
        return this.baseUrl;
    }

}

export default CutterWebsocket