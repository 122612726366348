import React from 'react';
import {JOB_STATUS_CATEGORY, JOB_STATUS_LABEL} from "../../types/models/Job.ts";
import {PulsingIcon} from "./PulsingIcon.tsx";
import {IPopulatedCutter, IPopulatedJob} from "../../types/FrontendTypes.ts";
import {ChipLine, SingleChipProps} from '@laser-project/kyui';
import {Circle, Help} from "@mui/icons-material";
import {CUTTER_STATUS} from "../../types/models/Cutter.ts";
import {Skeleton} from "@mui/material";

function getIconForCutter(cutter?: IPopulatedCutter, jobId?: string) {
    if (!cutter) {
        return <Help color={"warning"}/>;
    }

    if (cutter.status === CUTTER_STATUS.ONLINE) {
        if (cutter?.state.jobId === jobId) {
            return <PulsingIcon color={"success"}/>;
        }
        return <Circle color={"success"}/>;
    }
    return <Circle color={"error"}/>;
}

export function getCutterStateChip(cutter: IPopulatedCutter, jobId: string): SingleChipProps {
    return {
        id: 'cutter',
        label: cutter ? (cutter.name.length > 14 ? cutter.name.substring(0, 14) + '...' : cutter.name) : "Unassigned",
        props: {
            variant: "outlined",
            icon: getIconForCutter(cutter, jobId),
        }
    };
}

export function getJobStatusChip(job: IPopulatedJob): SingleChipProps | null {
    if (!job.cutter && (JOB_STATUS_CATEGORY.CREATED).includes(job?.status)) {
        return null;
    }
    return {
        id: 'status',
        label: JOB_STATUS_LABEL[job.status],
    };
}

export function getStatusChipProps(job?: IPopulatedJob): SingleChipProps[] {
    return [
        getCutterStateChip(job.cutter, job._id),
        ...(getJobStatusChip(job) ? [getJobStatusChip(job)] : []),
    ];
}

export function renderStatusChips(job?: IPopulatedJob) {
    if (!job) {
        return (<Skeleton width={300} height={30}/>);
    }

    const chips = getStatusChipProps(job);

    return (
        <ChipLine
            chips={chips}
        />
    );
}