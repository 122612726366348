import {useEffect} from "react";
import WebsocketAPISerde from "../../types/WebsocketAPISerde.ts";
import {IWebsocketAPIMessage, KNOWN_WEBSOCKET_API_MESSAGES} from "../../types/WebsocketAPITypes.ts";
import useWebSocket from "react-use-websocket";
import {useWebsocketNotifier} from "../../hooks/useWebsocketNotifier/useWebsocketNotifier.tsx";

export interface useWebsocketHandlerProps {
    refetch?: () => void;
    webSocketUrl: string;
    messageHandler?: Partial<{ [key in KNOWN_WEBSOCKET_API_MESSAGES]: (message: IWebsocketAPIMessage) => void }>;
}

export function useWebsocketHandler(props: useWebsocketHandlerProps) {
    const messages = useWebSocket(props.webSocketUrl, {
        shouldReconnect: () => true, heartbeat: true
    });

    useWebsocketNotifier({readyState: messages.readyState})

    useEffect(() => {
        if (!messages.lastMessage) {
            return
        }

        try {
            const message = WebsocketAPISerde.decode(messages.lastMessage.data);

            if (props.messageHandler && props.messageHandler[message.type]) {
                props.messageHandler[message.type](message)
            }

            if (props.refetch) {
                switch (message.type) {
                    case KNOWN_WEBSOCKET_API_MESSAGES.CREATE_JOB:
                    case KNOWN_WEBSOCKET_API_MESSAGES.JOB_STATUS_CHANGE:
                    case KNOWN_WEBSOCKET_API_MESSAGES.CUTTER_ASSIGNED:
                    case KNOWN_WEBSOCKET_API_MESSAGES.CREATE_CUTTER:
                    case KNOWN_WEBSOCKET_API_MESSAGES.RESET_JOB:
                    case KNOWN_WEBSOCKET_API_MESSAGES.CUTTER_UPDATED:
                    case KNOWN_WEBSOCKET_API_MESSAGES.CUTTER_STATUS_CHANGE: {
                        props.refetch?.();
                        break;
                    }
                    default:
                        break;
                }
            }
        } catch (e) {
            console.warn("Failed to parse message sent by cutter manager", e, messages.lastMessage)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages.lastMessage]);
}