import {IPopulatedCutter, IPopulatedJob} from "../../../types/FrontendTypes.ts";
import * as React from "react";
import Button from "@mui/material/Button";
import {CutterSelectionDialog} from "./CutterSelectionDialog.tsx";
import {JOB_STATUS_CATEGORY} from "../../../types/models/Job.ts";

export default function CutterSelectionButton({job, assignCutter, cutters}: {
    job: IPopulatedJob,
    assignCutter: (cutterId: string) => void,
    cutters: IPopulatedCutter[],
}) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleClickOpen = () => {
        setSelectedValue(job.cutter?._id);
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setSelectedValue(value);
        if (value && value !== job.cutterId) {
            assignCutter(value);
        }
    };


    return [
        <Button
            key={"assign-cutter-button"}
            disabled={!(JOB_STATUS_CATEGORY.CREATED).includes(job?.status)}
            color={!job.cutter ? "secondary" : "primary"}
            variant="contained" onClick={handleClickOpen}>
            {job.cutter ? job.cutter.name : "Assign cutter"}
        </Button>,
        <CutterSelectionDialog
            key="cutter-selection-dialog"
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            job={job}
            cutters={cutters}
        />

    ]
}