
const LoadingSpinnerStyles = {
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    child: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    text: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    message: {
        paddingTop: "1rem"
    },
    reason: {
        paddingTop: "0.2rem",
        color: "gray"
    }
}
export default LoadingSpinnerStyles;