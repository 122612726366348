import {ITimestamped} from "./Database.js";
import {IFeature} from "./Feature.ts";
import {IAction} from "./Action.ts";
import {IUserInputRequest} from "./UserInputRequest.ts";


export enum JOB_STATUS {
    UNASSIGNED = "UNASSIGNED",
    DELETED = "DELETED",

    ANALYSIS_PENDING = "ANALYSIS_PENDING",
    ANALYSIS_STARTED = "ANALYSIS_STARTED",
    ANALYSIS_FAILED = "ANALYSIS_FAILED",
    ANALYSIS_COMPLETE = "ANALYSIS_COMPLETE",

    EXECUTION_PENDING = "EXECUTION_PENDING",
    EXECUTION_STARTED = "EXECUTION_STARTED",
    EXECUTION_CALIBRATING = "EXECUTION_CALIBRATING",
    EXECUTION_FABRICATING = "EXECUTION_FABRICATING",
    EXECUTION_WAITING_FOR_INPUT = "EXECUTION_WAITING_FOR_INPUT",
    EXECUTION_FAILED = "EXECUTION_FAILED",
    EXECUTION_SUCCESS = "EXECUTION_SUCCESS",
}


export enum JOB_STATUS_LABEL {
    UNASSIGNED = "Unassigned",
    DELETED = "Deleted",

    ANALYSIS_PENDING = "Analysis Pending",
    ANALYSIS_STARTED = "Analyzing",
    ANALYSIS_FAILED = "Analysis Failed",
    ANALYSIS_COMPLETE = "Ready",

    EXECUTION_PENDING = "Execution Pending",
    EXECUTION_STARTED = "Executing",
    EXECUTION_CALIBRATING = "Calibrating",
    EXECUTION_FABRICATING = "Fabricating",
    EXECUTION_WAITING_FOR_INPUT = "Waiting for Input",
    EXECUTION_FAILED = "Failed",
    EXECUTION_SUCCESS = "Success",
}

export const JOB_STATUS_CATEGORY = {
    CREATED: [JOB_STATUS.UNASSIGNED, JOB_STATUS.ANALYSIS_PENDING, JOB_STATUS.ANALYSIS_STARTED, JOB_STATUS.ANALYSIS_FAILED, JOB_STATUS.ANALYSIS_COMPLETE],
    RUNNING: [JOB_STATUS.EXECUTION_PENDING, JOB_STATUS.EXECUTION_STARTED, JOB_STATUS.EXECUTION_CALIBRATING, JOB_STATUS.EXECUTION_FABRICATING, JOB_STATUS.EXECUTION_WAITING_FOR_INPUT],
    FINISHED: [JOB_STATUS.EXECUTION_FAILED, JOB_STATUS.EXECUTION_SUCCESS],
    EXECUTING: [JOB_STATUS.EXECUTION_STARTED, JOB_STATUS.EXECUTION_CALIBRATING, JOB_STATUS.EXECUTION_FABRICATING, JOB_STATUS.EXECUTION_WAITING_FOR_INPUT],
    DELETED: [JOB_STATUS.DELETED],
};



export interface IJobSpec {
    cutplan: string;
    title?: string;
    // user?: IUser;
    imageId?: string;
    cutterId?: string;
    sheets?: ISheetData[];
    sheetPrototype: ISheetDataPrototype;
    features?: IFeature[];
}


export interface IJob extends IJobSpec, ITimestamped {
    _id: string;
    status: JOB_STATUS;
    statusReason?: string;
    actionOrder?: IAction[];
    lastActiveActionId?: string | null;
    currentInputRequest?: IUserInputRequest | null;
}


export interface IJobModel extends IJob {
    _id: string;
}

// ToDo: Move somewhere else
export interface IReadonlyVector2 {
    readonly x: number;
    readonly y: number;
}

// ToDo: Move somewhere else
export interface IReadonlyPolygon2D {
    readonly points: IReadonlyVector2[];
}

export interface ISheetData {
    material: string;
    thickness: number;
    outline: IReadonlyPolygon2D;
}

export interface ISheetDataPrototype {
    outline: IReadonlyPolygon2D;
}