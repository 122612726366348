import AbstractRest from "./AbstractRest.ts";
import axios, {AxiosResponse} from "axios";
import {SuccessResponseData} from "../types/ReturnHelpers.ts";
import {IPopulatedCutter} from "../types/FrontendTypes.ts";
import {ICutterSpec} from "../types/models/Cutter.ts";

class CutterRest extends AbstractRest {

    constructor() {
        super("cutters");
    }

    getById(id: string): Promise<AxiosResponse<SuccessResponseData<IPopulatedCutter>>> {
        return axios.get(this.baseUrl + "/" + id);
    }

    createCutter(cutter: ICutterSpec): Promise<AxiosResponse<SuccessResponseData<IPopulatedCutter>>> {
        return axios.post(this.baseUrl, cutter)
    }

    delete(cutterId: string) {
        return axios.delete(this.baseUrl + "/" + cutterId)
    }

}

export default CutterRest;